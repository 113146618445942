import { $themeBreakpoints } from '@themeConfig'
import axiosIns from '@/libs/axios'
import constant from '@/global/const'
import Fingerprint2 from 'fingerprintjs2'

function getFingerPrint() {
  return new Promise(resolve => {
    Fingerprint2.get(c => {
      resolve(c)
    })
  })
}

function removeMinorAndPathVersion(string) {
  let result = string
  const regexBrow = /(\/\d+)([.\d]+)/g
  const regexOS = /( \d+)([_\d]+)/g
  result = string.replace(regexBrow, '$1')
  return result.replace(regexOS, '$1')
}

async function generateFingerprintId() {
  const options = ['userAgent', 'webdriver', 'language', 'colorDepth', 'deviceMemory', 'hardwareConcurrency', 'screenResolution', 'availableScreenResolution', 'timezoneOffset', 'timezone', 'indexedDb', 'addBehavior', 'openDatabase', 'cpuClass', 'platform', 'webglVendorAndRenderer', 'hasLiedLanguages', 'hasLiedResolution', 'hasLiedOs', 'hasLiedBrowser', 'touchSupport']
  let finger = await getFingerPrint()
  finger = finger.map(item => {
    if (item.key === 'userAgent') {
      return removeMinorAndPathVersion(item.value)
    }
    return item
  })
  return Fingerprint2.x64hash128(finger.filter(f => (options.includes(f.key) ? f : ''))
    .map(pair => pair.value)
    .join(), 31)
}

axiosIns.interceptors.request.use(async config => {
  Object.assign(config.headers, {
    'Device-Secure': await generateFingerprintId(),
  })
  return config
})

export default {
  namespaced: true,
  state: {
    currentRouteName: null,
    userData: null,
    windowWidth: 0,
    logoutNow: false,
    shallShowOverlay: false,
    provinces: constant.PROVINCE,
    districts: [],
    wards: [],
    streets: [],
    wardsByDistricts: [],
    streetsByDistricts: [],
    users: [],
    careers: [],
    roles: [],
    branches: [],
    VAT: 10, // %
    expireHiredProduct: '',
    expireProductOffice: '',
    isDev: window.location.hostname.includes('local') || localStorage.getItem('dev'),
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    updateCurrentRouteName(state, val) {
      state.currentRouteName = val
    },
    onTriggerLogout(state) {
      state.logoutNow = true
    },
    offTriggerLogout(state) {
      state.logoutNow = false
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    setUserData(state, val) {
      state.userData = val
    },
    updateRoles(state, val) {
      state.roles = val || []
    },
    updateUsers(state, val) {
      state.users = val.sort((a, b) => (a.fullname > b.fullname ? 1 : -1))
    },
    updateCareers(state, val) {
      state.careers = val.sort((a, b) => (a.fullname > b.fullname ? 1 : -1))
    },
    updateProvinces(state, val) {
      // hcm first
      const hcmFirst = []
      val.forEach(item => {
        if (item.code.toLowerCase() === 'sg'
          || item.code.toLowerCase() === 'hcm'
          || item.name === 'Hồ Chí Minh'
          || item.name === 'Sài Gòn'
        ) {
          hcmFirst.unshift(item)
        } else {
          hcmFirst.push(item)
        }
      })
      state.provinces = hcmFirst
    },
    updateDistricts(state, val) {
      state.districts = val || []
    },
    updateWards(state, val) {
      state.wards = val || []
    },
    updateStreets(state, val) {
      state.streets = val || []
    },
    updateBranches(state, val) {
      state.branches = val || []
    },
    updateStreetsByDistricts(state, val) {
      const arr = val || []
      state.streetsByDistricts = []
      const hashName = {}
      arr.forEach(item => {
        if (hashName[item.name]) return
        hashName[item.name] = true
        state.streetsByDistricts.push(item)
      })
    },
    updateWardsByDistricts(state, val) {
      state.wardsByDistricts = val || []
    },
    updateExpireProduct(state, val) {
      state.expireHiredProduct = val.result.length
    },
    updateExpireProductOffice(state, val) {
      state.expireProductOffice = val.result.length
    },
  },
  actions: {
    forgotPassword: (ctx, data) => axiosIns.post('/api.user/forgot-password', data),
    resetPassword: (ctx, data) => axiosIns.post('/api.user/reset-password', data),
    fetchStreetsByDistricts: async (ctx, q) => {
      const res = await axiosIns.get(`/api.province/district/street${q || ''}`)
      ctx.commit('updateStreetsByDistricts', res.data?.data)
      return res
    },
    fetchWardsByDistricts: async (ctx, q) => {
      const res = await axiosIns.get(`/api.province/district/ward${q || ''}`)
      ctx.commit('updateWardsByDistricts', res.data?.data)
      return res
    },
    fetchProvinces: async (ctx, q) => {
      const res = await axiosIns.get(`/api.province/${q || ''}`)
      ctx.commit('updateProvinces', res.data?.data)
      return res
    },
    fetchDistricts: async (ctx, data) => {
      const res = await axiosIns.get(`/api.province/${data.province_id}/district`)
      ctx.commit('updateDistricts', res.data?.data)
      return res
    },
    fetchWards: async (ctx, data) => {
      const res = await axiosIns.get(`/api.province/${data.province_id}/district/${data.district_id}/ward`)
      ctx.commit('updateWards', res.data?.data)
      return res
    },
    fetchStreets: async (ctx, data) => {
      const res = await axiosIns.get(`/api.province/${data.province_id}/district/${data.district_id}/street`)
      ctx.commit('updateStreets', res.data?.data)
      return res
    },
    fetchUsers: async (ctx, q) => {
      const res = await axiosIns.get(`/api.user/filter/${q || ''}`)
      ctx.commit('updateUsers', res.data?.data)
      return res
    },
    fetchCareers: async (ctx, q) => {
      const res = await axiosIns.get(`/api.career${q || ''}`)
      ctx.commit('updateCareers', res.data?.data)
      return res
    },
    fetchBranches: async (ctx, q) => {
      const res = await axiosIns.get(`/api.branch${q || ''}`)
      ctx.commit('updateBranches', res.data?.data)
      return res
    },
    getExpireProduct: async (ctx, q) => {
      const res = await axiosIns.get(`/api.product/expire-contract${q || ''}`)
      ctx.commit('updateExpireProduct', res.data?.data)
      return res
    },
    getExpireProductOffice: async ctx => {
      const res = await axiosIns.get('/api.product/expire-contract?product_type=office')
      ctx.commit('updateExpireProductOffice', res.data?.data)
      return res
    },
  },
}
